// Weight and italics

.font-weight-300 { font-weight: 300 !important; }
.font-weight-400 { font-weight: 400 !important; }
.font-weight-500 { font-weight: 500 !important; }
.font-weight-600 { font-weight: 600 !important; }
.font-weight-700 { font-weight: 700 !important; }
.font-weight-800 { font-weight: 800 !important; }
.font-weight-900 { font-weight: 900 !important; }


// Text decorations

.text-underline { text-decoration: underline; }
.text-through { text-decoration: line-through; }


// Text size

.text-xs { font-size: $font-size-xs !important; }
.text-sm { font-size: $font-size-sm !important; }
.text-lg { font-size: $font-size-lg !important; }
.text-xl { font-size: $font-size-xl !important; }


// Line heights

.lh-100 { line-height: 1; }
.lh-110 { line-height: 1.1; }
.lh-120 { line-height: 1.2; }
.lh-130 { line-height: 1.3; }
.lh-140 { line-height: 1.4; }
.lh-150 { line-height: 1.5; }
.lh-160 { line-height: 1.6; }
.lh-170 { line-height: 1.7; }
.lh-180 { line-height: 1.8; }


// Letter spacings

.ls-1 { letter-spacing: .0625rem; }
.ls-15 { letter-spacing: .09375rem; }
.ls-2 { letter-spacing: 0.125rem; }

// Color variations

@each $color, $value in $colors {
	@include text-emphasis-variant(".text-#{$color}", $value);
}

// Notiexpress colors

@each $color, $value in $notiexpress-colors {
	@include text-emphasis-variant(".text-#{$color}", $value);
	.text-#{$color} {
		color: $value;
	}
}

// Notiexpress fonts

.text-heading-1{
	font-family: 'Baloo 2', cursive;
	font-weight: bold;
	color: notiexpress-color("primary-default");
	font-size: 36px;
	line-height: 48px;
}

.text-heading-2{
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	color: $secondary-default;
	font-size: 24px;
	line-height: 32px;
}

.text-heading-3 {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	color: $secondary-dark-2;
	font-size: 18px;
	line-height: 24px;
}

.text-button {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 500;
	color: $neutro-1;
	font-size: 20px;
	line-height: 24px;
}

.text-dark-link-regular {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.text-link-regular{
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	color: $noti-info;
	font-size: 16px;
	line-height: 24px;
	&:hover {
		color: $noti-info;
	}
}
.text-link-bold {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	color: $noti-info;
	font-size: 18px;
	line-height: 28px;
	&:hover {
		color: $noti-info;
	}
}
.text-link-large {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	color: $noti-info;
	&:hover {
		color: $noti-info;
	}
}

.text-body-small {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: notiexpress-color("neutro-2");
}
.text-body-regular {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	color: $neutro-2;
	font-size: 16px;
	line-height: 24px;
}

.text-body-large {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 400;
	color: $neutro-2;
	font-size: 18px;
	line-height: 28px;
}
.text-caps-regular {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
}

.text-body-large-bold {
	font-family: 'Roboto', sans-serif;
	color: $neutro-2;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 28px;
}

.text-body-small {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	color: $neutro-2;
	font-size: 14px;
	line-height: 20px;
}

// Text classes for ck5 editor
h1.noti-heading-1, .ck.ck-button.ck-heading_heading1_noti-heading-1 {
	font-family: 'Baloo 2', cursive;
	font-weight: bold;
	color: $primary-default;
	font-size: 36px;
	line-height: 48px;
}

h2.noti-heading-2, .ck.ck-button.ck-heading_heading2_noti-heading-2 {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	color: $secondary-default;
	font-size: 24px;
	line-height: 32px;
}

h3.noti-heading-3, .ck.ck-button.ck-heading_heading3_noti-heading-3 {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	color: $secondary-dark-2;
	font-size: 18px;
	line-height: 24px;
}

p.body-large , .ck.ck-button.ck-heading_paragraph_body-large {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 400;
	color: $neutro-2;
	font-size: 18px;
	line-height: 28px;
}

p.body-regular , .ck.ck-button.ck-heading_paragraph_body-regular {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	color: $neutro-2;
	font-size: 16px;
	line-height: 24px;
}

p.body-small , .ck.ck-button.ck-heading_paragraph_body-small {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	color: $neutro-2;
	font-size: 14px;
	line-height: 20px;
}

p.body-large-bold , .ck.ck-button.ck-heading_paragraph_body-large-bold {
	font-family: 'Roboto', sans-serif;
	color: $neutro-2;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 28px;
}

p.body-small-bold , .ck.ck-button.ck-heading_paragraph_body-small-bold {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	color: $neutro-2;
	font-size: 14px;
	line-height: 20px;
}

p.dark-link-regular , .ck.ck-button.ck-heading_paragraph_dark-link-regular {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: $noti-info;
}

p.dark-link-bold , .ck.ck-button.ck-heading_paragraph_dark-link-bold {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	color: $noti-info;
	font-size: 18px;
	line-height: 28px;
}

p.dark-link-large , .ck.ck-button.ck-heading_paragraph_dark-link-large {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	color: $noti-info;
}

p.caps-regular , .ck.ck-button.ck-heading_paragraph_caps-regular {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
}