//
// Custom toggle
//

.custom-toggle {
  position: relative;
  display: inline-block;
  width: $custom-toggle-width;
  height: 1.5rem;

  input {
    display: none;

    &:checked {
      + .custom-toggle-slider {
        border: 2px solid $noti-success;

        &:before {
          background: $neutro-1;
          border: $noti-success solid 2px !important;
          transform: translateX(1.625rem);
        }

        &:after {
          content: attr(data-label-on);
          right: auto;
          left: 0
        }
      }
    }

    &:disabled {
      + .custom-toggle-slider {
        border: $custom-control-indicator-border-width solid $custom-control-indicator-disabled-bg;
      }

      &:checked {
        + .custom-toggle-slider {
          border: $custom-control-indicator-border-width solid $custom-control-indicator-disabled-bg;

          &:before {
            background-color: lighten($custom-control-indicator-checked-bg, 10%);
          }
        }
      }
    }
  }
}

.custom-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid $primary-default;
  border-radius: 34px !important;
  background-color: transparent;


  &::before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    left: -2px;
    bottom: -5px;
    border-radius: 50% !important;
    border: $primary-default solid 2px !important;
    background-color: $neutro-1;
    transition: $input-transition;
  }

  &::after {
    color: $primary-default;
    content: attr(data-label-off);
    display: block;
    font-family: inherit;
    font-weight: 600;
    font-size: .75rem;
    line-height: 24px;
    position: absolute;
    right: 2px;
    margin: 0 .21667rem;
    top: -4px;
    text-align: center;
    min-width: 1.66667rem;
    overflow: hidden;
    transition: all .3s ease
  }


}
