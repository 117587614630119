.bg-auth {
    position: absolute;
    background:
            linear-gradient(89.83deg, rgba(255, 255, 255, 0.82) 51.52%, rgba(255, 255, 255, 0) 75%),
            url("../../assets/img/theme/auth-background.jpg") no-repeat 65%;
    background-size: cover;
    overflow: hidden;
}

.bg-auth-body {
    position: absolute;
    background: url("../../assets/img/theme/auth-background.jpg") no-repeat center fixed;
    background-size: cover;
    z-index: -1;
    filter: blur(10px);
    width: 100%;
    height: 100%;
}

.auth-row {
    position: relative;
    z-index: 1;
}
