.main-content {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .container-admin {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        flex-direction: column;

    }

    // Navbar
    .navbar-top {
        position: sticky;
        left: 0;
        top: 0;
        width: 100%;
        height: 78px;
        z-index: 1;
        background-color: transparent;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }



    // Container
    .container-fluid {
        @include media-breakpoint-up(md) {
            padding-left: (
                $main-content-padding-x + $grid-gutter-width / 2
            ) !important;
            padding-right: (
                $main-content-padding-x + $grid-gutter-width / 2
            ) !important;
        }
    }
}

// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {
    @each $breakpoint, $dimension in $grid-breakpoints {
        &-#{$breakpoint} {
            @include media-breakpoint-up(#{$breakpoint}) {
                // Left
                &.fixed-left + .main-content {
                    margin-left: 88px;
                } // Right
                &.fixed-right + .main-content {
                    margin-right: $navbar-vertical-width;
                }
            }
        }
    }
}

.ov-x-auto {
    //overflow-x: auto;
}
.btn-group {
    flex-wrap: wrap;
}