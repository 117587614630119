//
// Icon buttons
//

// General styles

.btn {
  position: relative;
  text-transform: $btn-text-transform;
  transition: $transition-base;
  letter-spacing: $btn-letter-spacing;
  font-size: $input-btn-font-size;
  will-change: transform;

  &:hover {
    @include box-shadow($btn-hover-box-shadow);
    transform: translateY($btn-hover-translate-y);
  }

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  // Icons

  i:not(:first-child),
  svg:not(:first-child) {
    margin-left: 0.5rem;
  }

  i:not(:last-child),
  svg:not(:last-child) {
    margin-right: 0.5rem;
  }
}

// Remove translateY and margin animation when btn is included in a btn-group or input-group

.btn-group,
.input-group {
  .btn {
    margin-right: 0;
    transform: translateY(0);
  }
}

// Size variations

.btn-sm {
  font-size: $input-btn-font-size-sm;
}

.btn-lg {
  font-size: $input-btn-font-size-lg;
  margin-top: 15px;
  margin-bottom: 40px;
}

@media (max-width: 673px) {
  .btn-lg {
    width: 100%;
    max-width: 290px;
  }
}

// Some quick fixes (to revise)

// Fixes
[class*="btn-outline-"] {
  border-width: 1px;
}

.btn-outline-secondary {
  color: darken(theme-color("secondary"), 50%);
}

.btn-inner--icon {
  i:not(.fa) {
    position: relative;
    top: 2px;
  }
}

.btn-link {
  font-weight: $btn-font-weight;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    transform: none;
  }
}

.btn-neutral {
  color: theme-color("primary");
}

.btn-noti-primary {
  color: notiexpress-color("neutro-1");
  background-color: notiexpress-color("primary-default");
  border: 2px solid notiexpress-color("primary-default");
  border-radius: 8px !important;

  &:hover {
    color: notiexpress-color("neutro-1");
    background-color: notiexpress-color("primary-default");
    border: 2px solid notiexpress-color("primary-default");
    border-radius: 8px;
  }

  &:disabled {
    color: notiexpress-color("neutro-1");
    background-color: notiexpress-color("primary-default");
    border: 2px solid notiexpress-color("primary-default");
    border-radius: 8px;
  }
}

.btn-outline-noti-primary {
  border: 2px solid notiexpress-color("primary-default");
  color: notiexpress-color("primary-default");
  background-color: notiexpress-color("neutro-1");
  border-radius: 8px;

  &:hover {
    color: notiexpress-color("neutro-1");
    background-color: notiexpress-color("primary-default");
    border: 2px solid notiexpress-color("primary-default");
    border-radius: 8px;

    svg {
      stroke: notiexpress-color("neutro-1");
      fill: notiexpress-color("neutro-1");
    }
  }
}

.btn-outline-noti-secondary {
  border: 2px solid notiexpress-color("secondary-default");
  color: notiexpress-color("secondary-default");
  background-color: notiexpress-color("neutro-1");
  border-radius: 8px !important;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  &:hover {
    color: notiexpress-color("neutro-1");
    background-color: notiexpress-color("secondary-default");
    border: 2px solid notiexpress-color("secondary-default");
    border-radius: 8px;

    svg {
      stroke: notiexpress-color("neutro-1");
    }
  }
  &:disabled {
    color: notiexpress-color("neutro-3");
    background-color: notiexpress-color("neutro-1");
    border: 2px solid notiexpress-color("neutro-1");
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    svg {
      stroke: notiexpress-color("neutro-3");
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show>&.dropdown-toggle {
    color: notiexpress-color("neutro-1");
    background-color: notiexpress-color("secondary-default");
    border: 2px solid notiexpress-color("secondary-default");
    border-radius: 8px;

    svg {
      stroke: notiexpress-color("neutro-1");
    }
  }
}

.btn-noti-icon-primary {
  background: notiexpress-color("neutro-1");
  border-radius: 24px !important;
  border: 2px solid #D1E7F3;
  box-shadow: 0 3px 3px rgba(24, 49, 81, 0.12);

  &:disabled {
    border: 2px solid notiexpress-color("neutro-3");
    box-shadow: 0 3px 3px rgba(24, 49, 81, 0.12);
    svg {
      stroke: notiexpress-color("neutro-3")
    }
  }
}

