//
// Custom checkbox
//

.custom-checkbox {
  .custom-control-input ~ .custom-control-label {
    cursor: pointer;
  }

  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::before {
          border-color: notiexpress-color("primary-default");
          border-radius: 4px;
        }
        &::after {
          background-image: $custom-checkbox-indicator-icon-checked;
          border-color: notiexpress-color("primary-default");
        }
      }
    }

    &:focus {
      ~ .custom-control-label {
        &::before {
        }
        &::after {
        }
      }
    }

    &:disabled {
      ~ .custom-control-label {
        &::before {
          border-color: $custom-control-indicator-disabled-bg;
        }
      }

      &:checked {
        &::before {
          border-color: $custom-control-indicator-checked-disabled-bg;
        }
      }
    }
  }
}
