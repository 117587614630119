//
// Custom checkbox
//

.custom-checkbox {
    .custom-control-input ~ .custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
        border-color: notiexpress-color("primary-variant-1");
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &::before {
                    border-color: notiexpress-color("primary-default");
                    background-color: notiexpress-color("primary-default");
                }
                &::after {
                    background-image: $custom-checkbox-indicator-icon-checked;
                }
            }
        }

        &:disabled {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }
}
