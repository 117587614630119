// stylelint-disable declaration-no-important

.shadow-sm { box-shadow: $box-shadow-sm !important; }
.shadow { box-shadow: $box-shadow !important; }
.shadow-lg { box-shadow: $box-shadow-lg !important; }
.shadow-none { box-shadow: none !important; }
.shadow-card {
  box-shadow: 0 2px 4px rgba(24, 49, 81, 0.32);
}
.shadow-item {
  box-shadow: 0 2px 4px rgba(24, 49, 81, 0.32);
  border-radius: 16px;
}
