//
// Navabar collapse
//

// Collapse

//.navbar-collapse-header {
//    display: none;
//}

@include media-breakpoint-up(md) {
    body:not(.g-sidenav-pinned) {
        &:not(.g-sidenav-show) {
            .sidenav {
                max-width: 88px;
                overflow: hidden;

                .navbar-brand {
                    display: none;
                }
                .sidenav-toggler {
                    padding: 1.5rem 0;
                }
                .nav-link-text {
                    white-space: nowrap;
                    visibility: hidden;
                }
            }
        }

        .sidenav + .main-content {
            margin-left: 88px !important;
        }
    }
}

@include media-breakpoint-down(sm) {
    .navbar-nav {
        .nav-link {
            padding: 0.625rem 0;
            color: notiexpress-color("primary-default") !important;
        }

        .dropdown-menu {
            box-shadow: none;
            min-width: auto;

            .media {
                svg {
                    width: 30px;
                }
            }
        }
    }

    .navbar-collapse {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1050;
        margin: 0.7rem;
        overflow-y: auto;
        height: auto !important;
        opacity: 0;

        .navbar-toggler {
            width: 20px;
            height: 20px;
            position: relative;
            cursor: pointer;
            display: inline-block;
            padding: 0;

            span {
                display: block;
                position: absolute;
                width: 100%;
                height: 2px;
                border-radius: 2px;
                opacity: 1;
                background: #283448;
            }

            :nth-child(1) {
                transform: rotate(135deg);
            }

            :nth-child(2) {
                transform: rotate(-135deg);
            }
        }

        .navbar-collapse-header {
            display: block;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .collapse-brand {
            img {
                height: 36px;
            }
        }

        .collapse-close {
            text-align: right;
        }
    }

    .navbar-collapse.collapsing,
    .navbar-collapse.show {
        padding: 1.5rem;
        border-radius: $border-radius;
        background: #fff;
        box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
            0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
        animation: show-navbar-collapse 0.2s ease forwards;
    }

    .navbar-collapse.collapsing-out {
        animation: hide-navbar-collapse 0.2s ease forwards;
    }
}

@keyframes show-navbar-collapse {
    0% {
        opacity: 0;
        transform: scale(0.95);
        transform-origin: 50% 50%;
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes hide-navbar-collapse {
    from {
        opacity: 1;
        transform: scale(1);
        transform-origin: 50% 50%;
    }

    to {
        opacity: 0;
        transform: scale(0.95);
    }
}
